// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

body {
  background-color: $gray;
}

//ancho máximo para las imagenes y videos en el resumen del admin
#certificacion-img,
#certificacion-video {
  max-width: 400px !important;
}
//centramos las imagenes dentro del modal
#modalImageCertificacion {
  .modal-body {
    display: flex;
    justify-content: center;
  }
}

.container {
  max-width: 1820px;
}
.buscadorTop {
  width: auto;
}

.sinContenido {
  min-height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.3rem;
  }
}

.idPrecios {
  border-spacing: 0.3rem;
  thead tr th {
    width: 100px;
    text-align: center;
    padding: 0.3rem 0;
  }
  tbody tr td:first-child {
    font-weight: bold;
  }
  tbody tr td input {
    text-align: center;
  }
}

.bloque {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameEscort {
  font-weight: bold;
}
.copiarAccesos {
  width: 300px;
}

.agregarPublicacion {
  width: 200px;
  margin-left: auto;
}

#topAdmin {
  width: 100vw;
}

.table {
  color: #676a6c;
}

.tituloSeccion {
  font-weight: bold;
  margin: 1rem 0 1.5rem 0;
}

//Colores para los sitios en el resumen
//resumen

$colors: (
  "pe": #ff4343,
  "lp": #2ca9c9,
  "ce": #575f61,
  "cn": #a58c1b,
  "ss": #1b792f,
);

@each $name, $color in $colors {
  .lebel-#{$name} {
    color: white;
    background-color: $color;
    border: 0px solid $color;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 27px;
    height: 27px;
    font-size: 11px;
    line-height: 1.5;
    font-weight: bold;
  }
}

.verde {
  color: var(--green);
}

.resumen-publicaciones {
  display: inline-flex;
  align-items: baseline;

  dd {
    margin: 0 1rem 0 0.5rem;
  }
}

//para el buscador resultados
.resultado-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  .panel {
    margin-right: 1rem;
  }
}
.botones {
  display: flex;
  justify-content: flex-end;
}

.label-edit {
  color: #fff;
  background-color: var(--purple);
}

.wrap-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header {
  flex-direction: column;
  align-items: center;

  .modal-title {
    font-size: 15px;
  }

  .close {
    position: absolute;
    right: 16px;
  }
}

//AGENCIAS

.agencias-buscador {
  display: flex;
  align-items: center;
}

.historialTop {
  display: flex;
}

//historial de pagos
.historial-content {
  width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    h3 {
      margin: 0;
    }
  }
}

.historialInfo {
  overflow: auto;
  margin-bottom: 0;

  dt,
  dd {
    float: left;
  }

  dt {
    font-weight: normal;
    width: 191px;
  }

  dd {
    font-weight: bold;
    width: 67px;
    margin-bottom: 3px;
    text-align: right;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.nov-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wizard-big.wizard > .content {
  min-height: auto !important;
}

.portadaWizard {
  width: 11rem;
}

.nuevaPubliResumen {
  dt,
  dd {
    float: left;
  }
  dt {
    clear: left;
    margin-right: 5px;
  }
}


