// -----------------------------------------------------------------------------
// GALERIA DEL PERFIL
// -----------------------------------------------------------------------------

//barra superior
.barra-top {
  &__datos {
    display: flex;
    flex-direction: row;
    position: relative;

    //portada de la barra
    img {
      height: 7rem;
      width: 7rem;
      border: 4px solid $navy;
    }

    .datos-publicacion {
      margin-left: 1.5rem;
      position: relative;
      z-index: 0;
      h2 {
        margin: 0 0 0.3rem;
        font-weight: 400;

        //Estrella exclusiva
        span:first-child {
          position: relative;
          .fa-star {
            position: absolute;
            top: 11px;
            left: -22px;
            font-size: 0.9rem;
          }
        }
      }

      h3 {
        margin: 0 0 0.3rem;
        font-weight: bold;

        a {
          color: $navy;
          font-weight: bold;

          &:hover {
            color: $text-color;
          }
        }
      }

      p {
        margin: 0;
        b {
          margin-left: 5px;
        }
      }
    }
  }

  &__botones {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-content {
      display: flex;
      justify-content: space-evenly;
      width: 60%;
      @include media('<=phone'){
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
      }
    }
  }

  &__config {
    .checkbox-content {
      div {
        text-align: right;

        label {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__alertas {
    min-height: 1rem;
  }
  @include media("<575px", "screen") {
    padding: 2rem 0;
  }
}

.nav {
  @include media('<=phone'){
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

$clases: (
  "perfil-menu": $navy,
  "perfil-menu-fotos": $navy,
  "perfil-menu-portadas1": $navy,
  "perfil-menu-portadas2": $navy,
  "perfil-menu-destacadas": $navy,
  "perfil-menu-historias": $navy,
  "perfil-menu-publicaciones": $navy,
  "perfil-menu-videos": $navy,
  "pills-tab-posiciones": $navy,
  "pills-tab": $navy,
  "pills-pubModelos-tab": $navy
);

@each $name, $colors in $clases {
  ##{$name} {
    .nav-link {
      margin-right: 5px;
      padding: 0.5rem 1rem;
      &.active {
        color: white;
        background-color: $colors;
      }
      &:hover {
        color: white;
        background-color: lighten($colors, 10%);
      }
      @include media('<=phone'){
        white-space: nowrap;
      }
    }
  }
}

$estados: (
  "activa": var(--green),
  "suspendida": var(--purple),
  "congelada": var(--cyan),
  "terminada": var(--dark),
  "deuda": var(--red)
);
@each $estado, $colorestado in $estados {
  .#{$estado} {
    color: $colorestado;
  }
}

.ibox-tools {
  top: 8px;
}
//herramientas de la galeria, para cortar, editar, etc
.tool-gallery {
  position: relative;
  width: 50px;
  display: flex;
  height: 29px;
  margin-left: 20px;
}

//listado izquierdo de galeria
.columna-galeria {
  &__botones {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    .btn {
      margin-bottom: 0.5rem;
    }
  }

  &__listado {
    &-ul {
      list-style: none;
      padding: 0;
      li {
        padding: 0.2rem 0;
        border-bottom: 1px solid $border-color;

        label {
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          align-items: center;
          margin-bottom: 0;

          a {
            order: 1;
            @include ellipsis(140px);

            i {
              margin-right: 0.2rem;
            }

            .fa-folder {
              display: inline-block;
            }

            .fa-folder-minus {
              display: none;
            }

            &.ejecutado {
              opacity: 0.4;

              .fa-folder {
                display: none;
              }

              .fa-folder-minus {
                display: inline-block;
              }
            }
          }

          .galeria-detalle {
            margin: 0 5px 0 auto;
            order: 2;

            .cantidad-fotos {
              margin-left: 5px;
            }
          }

          .icheckbox_square-green {
            order: 3;
          }

          button {
            order: 3;
            border: 0;
            background-color: transparent;

            i {
              color: var(--blue);
            }
          }
        }
      }
    }
  }
}

.bootstrap-timepicker {
  position: relative;
  width: auto !important;
}

//rectangulo que avisa que tiene observaciones
.bloque-alertas {
  background-color: var(--yellow);
  width: auto;
  max-width: 444px;
  line-height: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px;

  &_top {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    > i {
      color: var(--dark);
      font-size: 1rem;
      float: left;
      margin-right: 0.3rem;
    }
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  p {
    color: var(--dark);
    margin: 0;
    line-height: 1.5;
  }
}

//texto superior con los datos de la modelo
.dl-datos {
  margin: 1rem 0 0;
  dd,
  dt {
    line-height: 1.42857;
    padding: 8px;
  }
  dd:nth-of-type(odd),
  dt:nth-of-type(odd) {
    background: $gray;
  }

  dt {
    float: left;
    font-weight: normal;
  }

  dd {
    font-weight: bold;
    color: $text-color;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
  }
}

form#listado-documentos {
  font-weight: bold;
  color: #676a6c;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.dl-datos2 {
  margin: 0.3rem 0 0;
  dd,
  dt {
    padding: 3px 0 3px 7px;
  }
  dd:nth-of-type(odd),
  dt:nth-of-type(odd) {
    background: $gray;
  }

  dt {
    float: left;
    font-weight: normal;
  }

  dd {
    font-weight: bold;
    color: $text-color;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
  }
}
//efecto cto para desenfocar el nombre de la modelo
.text-blur {
  color: transparent;
  text-shadow: 0 0 5px $text-color;

  &:hover {
    color: $text-color;
    text-shadow: 0 0 0 $text-color;
    cursor: pointer;
  }
}

//en el perfil para agregar los documentos
.tag-document {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  border: 1px solid $border-color;
  width: 10rem;
  max-width: 12rem;
  padding: 0.3rem;
  align-items: center;

  &__input {
    height: 2rem;
    padding-top: 0.3rem;
    margin: 0 0.3rem;

    label {
      margin: 0;
    }
  }

  &__document {
    a {
      &:hover {
        color: $navy;
      }
      @include ellipsis(100px);
      font-size: 11px;
    }
  }

  &:hover {
    background-color: $border-color;
  }
}

.obs-content {
  background: $gray;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;

  &__top {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1rem;

    span:first-child {
      margin-right: 0.5rem;
    }

    span:last-child {
      margin-left: auto;
    }
  }

  &__body {
    .obs-file {
      margin-right: 0.5rem;
      display: inline-block;

      i {
        font-size: 1.5rem;
      }
    }
  }

  &__bottom {
    display: flex;
    &-pic {
      width: 50%;
      a {
        font-size: 1.3rem;
        margin: 0 5px;
      }
    }
    &-button {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}

.checkbox-content {
  .form-group {
    label {
      div {
        margin-right: 5px;
      }
    }
  }
}


.titulo-sercicio {
  margin: 5px 0;
}

.opcion-servicios {
  display: flex;
  flex-direction: row;
  span {
    font-weight: bold;
    width: 25px;
    height: 22px;
    text-align: center;
    &.incluidos {
      color: $navy;
    }
  }
}

//GALERIA -------------------------------------------
.checkbox-historia-1,
.checkbox-video-1,
span.checkbox-gallery-1 {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  div {
    margin-bottom: 0.5rem;

    span {
      margin-right: 0.3rem;
    }
  }
}

.selectedImg {
  border: 2px solid $navy;
  border-radius: 5px;
}

.activar-photo ~ .foto-item .existe {
  opacity: 0.45;
  filter: grayscale(100%);
}


//iconos del ojo y candado
//candado que bloquea la galeria completa

.propietario-galeria {
  p {
    margin: 0;
  }
}

.visible-gallery {
  height: 1.5rem;
  width: 1.5rem;

  i {
    margin-top: 0.2rem;
  }
}

$oc: (
  "poraprobar": rgba($blue, 0.2),
  "publicado": white,
  "bloqueado": rgba($gray-700, 0.2)
);

@each $op1, $opc in $oc {
  .#{$op1} {
    background: $opc;
  }
}

.infocomemment {
  display: flex;
  list-style: none;
  padding: 0;
  @each $op1, $opc in $oc {
    li {
      width: 110px;
      text-indent: 23px;
      position: relative;
      background: transparent;
      &.#{$op1}:after {
        content: "";
        border: 1px solid $gray-700;
        border-radius: 4px;
        display: block;
        position: absolute;
        height: 20px;
        width: 20px;
        background: $opc;
        top: 0;
      }
    }
  }
}

.comment-content {
  display: flex;
  flex-flow: row wrap;
  .perfilComentario {
    display: flex;
    flex-flow: column nowrap;
    padding: 11px;
    border: 1px solid $gray-700;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(100% / 2 - 10px);
    &_top {
      display: inline-flex;
      &-input {
        width: auto;
        margin-right: 5px;
      }
      div:nth-child(2) {
        width: 40%;
      }
      div:nth-child(3) {
        width: 40%;
      }
      &-usuario {
        display: flex;
        flex-direction: column;
        .date {
          font: 10px;
        }
      }
    }
  }
}
//comentarios

//fin candado
.desbloqueada {
  color: var(--green);
}

.bloqueada {
  color: $navy;
}

.listado-videos {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  &__botones {
    display: flex;
    flex-flow: column wrap;

    .btn {
      margin-bottom: 0.5rem;
    }
  }

  //comienza el listado de videos
  &__listado {
    //el video
    &-item {
      width: 100%;
      margin-bottom: 1rem;

      .handle-video {
        background: darken($gray, 3%);
        width: 100%;
        height: 35px;
        display: flex;
        align-items: baseline;
        @include border-top-radius(5px);

        //input
        #video-input-select {
          margin: 0 0.5rem;
        }
      }

      .video-item {
        width: 100%;
        height: auto;

        label {
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          align-items: center;
          margin-bottom: 0;
          position: relative;
          height: inherit;
          width: 100%s;

          ///videos
          .video-seccion {
            display: flex;
            flex-direction: column;
            height: inherit;
            width: 100%;

            &_top {
              background: darken($gray, 1%);
              display: block;
              height: inherit;

              video {
                width: 100%;
                height: 200px;
              }
              img {
                max-width: 100%;
                height: auto;
              }
            }

            &_bottom {
              &-data {
                width: 170px;
                margin: 0.6rem 0 0;

                dt {
                  float: left;
                  font-weight: normal;
                  margin-right: 0.2rem;
                }

                dd {
                  font-weight: bold;
                }

                dd,
                dt {
                  font-size: 0.7rem;
                  line-height: 0.8;
                }
              }
            }
          }
        }
      }
    }

    &-ul {
      li {
        padding: 0.2rem 0;
        border-bottom: 1px solid $border-color;

        label {
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          align-items: center;
          margin-bottom: 0;

          a {
            order: 1;

            i {
              margin-right: 0.2rem;
            }

            .fa-folder {
              display: inline-block;
            }

            .fa-folder-minus {
              display: none;
            }

            &.ejecutado {
              opacity: 0.4;

              .fa-folder {
                display: none;
              }

              .fa-folder-minus {
                display: inline-block;
              }
            }
          }

          input {
            order: 2;
            margin-left: auto;
          }

          button {
            order: 3;
            border: 0;
            background-color: transparent;

            i {
              color: var(--blue);
            }
          }
        }
      }
    }
  }
}

.mover-video {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.ui-sortable .handle-video {
  cursor: move;
}

.menu-galerias {
  height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  box-shadow:
    0 1px 8px rgba(0, 0, 0, 0.05),
    0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @include media('<=phone'){
    height: 80px;
  }
}

#list-input-select:checked + a {
  color: $navy !important;
}

#list-input-select:checked + .video-seccion .video-seccion_left {
  box-shadow: -1px 0 12px 4px #2e62b9;
}

.input-date {
  display: flex;
  .date-gallery {
    background: transparent !important;
    span {
      margin-right: 5px;
    }
  }
}

.propietario-galeria {
  margin-left: auto;
  margin-right: 0.5rem;
  cursor: default;
}

hr {
  margin: 0.5rem 0;
}

.portadas-select {
  width: 100%;
  display: flex;
  flex-direction: column;

  &_actual {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: auto;
      width: 180px;
      max-width: 180px;
      border-radius: 4px;
    }
  }

  &_anteriores {
    h4 {
      margin-bottom: 1rem;
    }

    .portadas-old {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      list-style: none;
      padding: 0;

      li {
        a {
          img {
            height: auto;
            width: 65px;
            max-width: 65px;
            border-radius: 4px;
          }

          &:hover {
            img {
              box-shadow: -1px 0 1px 3px $navy;
            }
          }
        }
      }
    }
  }
}

//ul con el listado de fotos
.galeria-fotos {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  list-style: none;
  padding: 0;

  &_item {
    width: 100px;
    margin: 0 1rem 1rem 0;

    &.destacada-li {
      width: 220px;
    }

    &.video-li {
      height: 250px;
      //width: auto;
    }

    &-top {
      position: relative;
      height: auto;

      &.movil-top {
        height: 107px;
      }

      .icheckbox_square-green {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 1;
        background-color: #ffffff;
        border-radius: 4px;
      }

      .foto-item {
        background-color: $gray;
        border: 2px dashed transparent;
        height: 148px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.movil-item {
          height: 102px;
        }

        &.destacada-item {
          height: 102px;
        }

        &.video-item {
          height: 220px;

          video {
            height: 100%;
            width: auto;
          }
        }

        &.no-existe {
          border-color: #00ff37;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }

      button {
        position: absolute;
        bottom: 0.5rem;
        left: 0.5rem;
        z-index: 1;
      }
    }

    &-bottom {
      margin-top: 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      z-index: 1;
    }
  }
}

//ul con el listado de fotos
.actividad-listado {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  list-style: none;
  padding: 0;

  &_item {
    width: calc(100% / 3 - 8px);
    margin: 0 0.5rem 0.5rem 0;

    &-top {
      position: relative;
      height: auto;

      .icheckbox_square-green {
        position: absolute !important;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
      }

      .text-item {
        background-color: $gray;
        border: 2px dashed transparent;
        display: flex;
        padding: 0.8rem;
        flex-direction: column;

        p {
          margin-top: 1.5rem;
        }
      }

      button {
        position: absolute;
        bottom: 0.5rem;
        left: 0.5rem;
        z-index: 1;
      }
    }

    &-bottom {
      margin-top: 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      z-index: 1;
    }

    @include media("<=1280px") {
      width: 100%;
    }
  }
}

//ul con el listado de fotos

.ocultar-foto {
  .fa-eye-slash {
    display: none;
  }

  .fa-eye {
    display: block;
  }
}

.ver-foto {
  .fa-eye-slash {
    display: block;
  }

  .fa-eye {
    display: none;
  }
}

.imagen-inactiva {
  filter: grayscale(100%);
  opacity: 0.35;
}
//este era el css original
/* .ocultar-foto ~ .foto-item img {
  filter: grayscale(100%);
  mix-blend-mode: screen;
} */

/* .icheckbox_square-green.checked ~ .foto-item img {
  box-shadow: -1px 0 1px 3px $navy;
}

.icheckbox_square-green.checked ~ .foto-item video {
  box-shadow: -1px 0 1px 3px $navy;
} */

.icheckbox_square-green.checked ~ .foto-item {
  border: 3px solid $navy;
  border-radius: 4px;
}

/*codigo anterior sin icheck
.select-foto[type="checkbox"]:checked ~ .foto-item {
  box-shadow: -1px 0px 12px 4px #2e62b9;
}

.select-text[type="checkbox"]:checked~.text-item {
  box-shadow: -1px 0px 12px 4px #2e62b9;
}
*/

///////////// ------ PUBLICACIONES
//columna izquierda

.publicaciones-content {
  .publicaciones-content__list {
    .pub-resumen {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-right: 0.4rem;

        &:nth-child(odd) {
          font-weight: bold;
        }
      }

      button {
        margin-left: auto;
      }
    }

    .pub-detalle {
      display: flex;
      flex-direction: row;
      background: $gray;
      padding: 1rem 0.5rem;

      dl {
        width: 50%;
        line-height: 1;
        margin-bottom: 0;

        dt {
          font-size: 0.7rem;
          float: left;
          font-weight: normal;
          margin-right: 0.3rem;
        }

        dd {
          font-size: 0.7rem;
          font-weight: bold;
        }
      }
    }
  }
}

.pub-titulo-bloque {
  span {
    margin-right: 0.7rem;

    &:nth-child(odd) {
      font-weight: bold;
    }
  }
}

.pub-link-perfil {
  color: $navy;
  text-decoration: underline;
}

.pub-footer-bloque {
  span {
    font-weight: bold;
  }
}

.tabla-publicacion {
  tbody {
    tr {
      td:last-child {
        font-weight: bold;
      }
    }
  }
}

#AcordionPublicacion {
  .card {
    &-header {
      padding: 0.5rem;

      h2 {
        margin: 0;

        button {
          background: transparent;
          margin: 0;
          border: none;
        }
      }
    }

    &-body {
      max-height: 12rem;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

.pub {
  $sizeP: 0.7rem;
  border-radius: 2px;
  height: $sizeP;
  width: $sizeP;
  display: block;
  margin-left: 0.3rem;
  &.activa {
    background-color: var(--green);
  }
  &.suspendida {
    background-color: var(--purple);
  }
  &.congeladas {
    background-color: var(--cyan);
  }
}

.referencias {
  display: flex;
}
.ref-ul {
  display: flex;
  align-items: center;
  li {
    color: $text-color;
    &:first-child {
      margin-right: 0.3rem;
    }
    &:last-child {
      margin-right: 0.6rem;
    }
  }
}

.nav-pills .nav-link.active {
  &.activa {
    background-color: var(--green);
    &:focus,
    &:hover {
      color: white !important;
    }
  }
  &.suspendida {
    background-color: var(--purple);
    &:focus,
    &:hover {
      color: white;
    }
  }
  &.congeladas {
    background-color: var(--cyan);
    &:focus,
    &:hover {
      color: white;
    }
  }
}

.tabla-pub-agencia {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
      td:nth-child(1) {
        width: 5%;
      }
      td:nth-child(2) {
        width: 10%;
      }
      td:nth-child(3) {
        width: 13%;
      }
      td:nth-child(4) {
        width: 15%;
      }
      td:nth-child(5) {
        width: 10%;
      }
      td:nth-child(6) {
        width: 15%;
      }
      td:nth-child(7) {
        width: 10%;
      }
      td:nth-child(8) {
        width: 10%;
      }
    }
  }
}

.publicaciones__agencia {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: start;

  &-item {
    background-color: $gray;
    border: 1px solid $gray;
    display: inline-flex;
    padding: 10px;
    border-radius: 4px;
    margin-right: 0.8rem;

    &_img {
      position: relative;
      float: left;
      height: 110px;
      width: 110px;
      a {
        bottom: 0;
        background: rgba(black, 0.8);
        position: absolute;
        z-index: 2;
        color: white;
        font-weight: bold;
        font-size: 12px;
        padding: 0 0.3rem;
        @include ellipsis(140px);
        line-height: 2;
        width: inherit;
        text-align: center;
      }
      img {
        position: absolute;
        z-index: 1;
      }
    }
    &_info {
      margin-left: 0.5rem;
      width: 130px;
      ul {
        li {
          color: $text-color;
          p {
            color: $text-color;
            margin: 0;
          }
          div:last-child {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.menu-publicaciones-agencia {
  li a {
    color: $text-color !important;
  }
}
.btn-publi {
  margin-top: 1rem;
}

.overflow-y {
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-height: 1000px;
}

/* .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .15);
} */

.tabla-historial-resumen {
  tbody {
    tr {
      td:nth-child(1) {
        width: 17%;
      }
      td:nth-child(2) {
        width: 60%;
      }
      td:nth-child(2) {
        width: 30%;
      }
    }
  }
}

/* .tabla-historial-pagos {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;

  thead tr th,
  tbody tr td {
    color: #6c7377;
    border-color: transparent;
    line-height: 1.42857;
    padding: 8px;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #efefef;
    }
  }
}
 */

.canvas-container {
  margin: 0 auto;
}

.spinner-multiple {
  position: absolute;
  z-index: 3;
  left: 50%;
}

//amigas
.amiga-solicitud {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-bottom: 1px solid #dee2e6;
  padding: 5px 0;
  img {
    height: 3.5rem;
    width: 3.5rem;
  }
  &-botones {
    margin-left: auto;
  }
  &:last-child {
    border-bottom: none;
  }
}

//amigas
.amiga-aceptada {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-right: 10px;
  img {
    border-radius: 5px;
    height: 10rem;
    width: 10rem;
  }
}

//para el modal
#enumeracion {
  margin: 0 8px;
}

.modal-sppiner {
  position: relative;

  #edicionmultiple {
    position: relative;
    z-index: 20;
  }
}
#spinner-multiple {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 21;
  transform: translate(-50%, -50%);
}

#spinner-icono {
  @extend #spinner-multiple;
}

#spinner-banner {
  @extend #spinner-multiple;
}

#portada-home {
  @extend #spinner-multiple;
}

#spinner-simple {
  @extend #spinner-multiple;
}

// para dar un margen en la parte inferior de la navegación
.contenido-ficha {
  .nav {
    margin-bottom: 10px !important;
  }
}

li.nav-item {
  white-space: nowrap;
}

.galeria-bloqueada {
  color: var(--blue);
}

//margin para el listado de servicios
.span-content {
  margin-bottom: 0.8rem;
}

//altura de la caja de texto
textarea#descripcion-0-texto {
  height: 300px;
}
