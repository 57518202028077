.form-check-label {
  margin-left: 0.2rem;
}

//clases para fijar los bloques
.topAdmin {
  position: fixed;
  z-index: 10;
}

.posicionesBuscador {
  position: fixed;
  top: 73px;
}

.posicionesBloques {
  margin: 53px 0 0 25%;
}

.fijoActivo {
  color: #1AB395;;
}

.resultadoFijo {
  margin-top: 1rem;
  overflow-y: scroll;
  height: 630px;

}

//fin

#posiciones-content,
#posiciones-content-vip
#posiciones-content-masajes,
#posiciones-content-destacadas {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#posicionTop,
#posicionMiddle,
#posicionBottom,
#posicionVip,
#posicionMasajes,
#posicionDestacadas {

  h5 {
    text-align: center;
    margin-top: 2rem;
  }
}

#posicionMiddle {
  background: $gray;
}

//div para poner las posiciones fijas
#admBloques {
  border-spacing: 1.4em;
  border-collapse: separate;
  width: 100%;
}

//colores para las posiciones
$colorpos: (
  segunda: var(--pink),
  tercera: var(--info),
  cuarta: var(--yellow),
  quinta: var(--purple),
  sexta: var(--teal),
  septima: var(--red),
);

.admFijas {
  border-spacing: 15px;
  border-collapse: separate;
  @for $i from 2 through 7 {
    tr:nth-child(#{$i}) td {
      border-color: map-get($colorpos, nth(map-keys($colorpos), ($i) - 1));
    }
  }
}


@each $name, $bgcolor in $colorpos {
  .#{$name}-fila {
    background-color: $bgcolor;
    .admPid:before {
      color: white !important;
    }
  }
}

$filasfijas: (
  segunda: white,
  tercera: white,
  cuarta: black,
  quinta: white,
  sexta: white,
  septima: white,
);

@each $name, $color in $filasfijas {
  .admPinfo.#{$name}-fila {
    .admPid, .admPrank {
      color: $color;
      margin-bottom: 0 !important;
    }
    .admPid::before {
      content: "id";
      color: $color !important;
      margin-right: 0.3rem;
    }
  }
}



.pos-referencia {
  margin-left: auto;
}

ul.pos-referencia_ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  @for $i from 1 through 6 {
    li:nth-child(#{$i}) {
      margin-right: 1rem;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      &:before {
        background: map-get($colorpos, nth(map-keys($colorpos), ($i)));
        content: "";
        border-radius: 4px;
        margin-right: 5px;
        width: 13px;
        height: 13px;
        display: block;
      }
    }
  }
}


.tdFijasVacia,
.tdFijasPortada,
.tdFijasHover {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #ccc;
  background-color: rgba(0, 0, 0, 0);
  width: 140px;
  height: 230px;
}

.tdFijasPortada {
  border-color: transparent !important;
  background: $gray;
  padding: 1%;
}

.admResultado {
  margin-top: 1rem;
}

ul.admRportadas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  /* ul que contiene las portadas */
  .admPRli {
    list-style: none;
    width: calc((100% / 3) - 1rem);
    margin-bottom: 5%;
    margin-right: 1rem;

    @include media(">=1500px", "screen") {
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    @include media("<1500px", "screen") {
      width: calc((100% / 2) - 8rem);
      min-width: 110px;
      margin-right: 0.8rem;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
  }
}



.admPortadas {
  height: auto;
  width: 100%;
  margin: 0 auto;
  overflow: auto;

  /* div con la id y ranking */
  .admPinfo {
    width: 100%;
    padding: 0 2%;
    display: flex;
    justify-content: space-between;

    > div {

    }
  }

  .admImgName {
    position: relative;
    line-height: 1;

    .admImgName {
      background: rgba(0, 0, 0, 0.85);
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;

      p {
        font-size: 12px;
        color: white;
        margin-bottom: 0;
        line-height: 2;
        @include ellipsis(110px);
      }
    }

    .admImgPortada {
      width: 100%;
    }
  }
}

p.admPid {
  margin-bottom: 0;
}





/* fin P */

/* div contenedor de los iconos */
.admPedit {
  display: flex;
    width: 100%;
    margin-top: 2%;
    flex-flow: row nowrap;
    padding: 0;
    /*
  display: table;
  width: 100%;
  margin: 0;
  border-collapse: collapse;
  margin-top: 2%;*/

  li {
    display: table-cell;
    width: 20%;
    float: left;
    text-align: center;

    a {
      outline: none;
    }
  }
}

#pos-edit,
#pos-exclusiva,
#pos-finanzas,
#pos-datos {
}

.popover-body {
  box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.7);
}

.popover-icon {
  i {
    color: $label-badge-color;
    &:hover {
      color: $text-color;
    }
  }
}

.admPedit {
  li a {
    color: $label-badge-color;
    &:hover {
      color: $text-color;
    }
  }
}



.admPbloque {
  display: flex;
  justify-content: space-around;
  margin-top: 0.2rem;
  padding: 0;
  list-style: none;

  li {
    font-size: 12px;
  }
}

//.popover-block-container {}

.popover-header {
  display: none;
}

.popover {
  max-width: 306.6px;
  border-radius: 4px;
  border: none;

  .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
}

.popover-body {
  border: none;
  margin: 0;

  font-size: 12px;
  z-index: 2;
  line-height: 1.5;
  min-width: 100px;
}

.link-posiciones {
  margin-bottom: 0;
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.2rem;

    a {
      color: $gray-800;
      outline: none;

      i {
        margin-right: 5px;
      }

      &:hover {
        display: block;
        width: 100%;
        color: $navy !important;
      }
    }
  }
}



.pos-info {
  margin-bottom: 0;

  dt,
  dd {
    margin: 0 0 0.5rem 0;
  }

  dt {
    float: left;
    margin-right: 0.3rem;
    font-weight: normal;
  }

  dd {
    font-weight: bold;
  }
}

.popover-body {
  .pos-datos {
    p {
      margin-bottom: 0;
      font-weight: bold;

      &:first-child {
        font-size: 14px;
      }
    }
  }

  p {
    margin-bottom: 0;

    span {
      font-weight: bold;
    }
  }
}


//posiciones tabs destacadas

.destacadas-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  margin-top: 20px;

  &_perfil {
    background-color: $gray;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.5);
    max-width: 350px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 35px 25px 0;

    &-top {
      img {
        width: 100%;
      }
    }
    &-bottom {
      display: flex;
      padding: 10px;

      > div {
        width: 100%;
      }

      &_left{
        display: flex;
        flex-direction: column;
        align-items: start;

        p {
          font-size: 20px;
          line-height: 1;
          font-weight: bold;
          margin: 0;
        }
        a {
          font-size: 12px;
          font-weight: bold;
        }
      }
      &_right {
        text-align: right;
        .fa-whatsapp {
          font-size: 20px;
        }
        a {
          font-size: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
